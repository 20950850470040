import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';

const SentryWrapper = ({ children }) => {
    const userInfo = useSelector(store => store.userInfo);

    if (userInfo) {
        Sentry.setUser({
            id: userInfo.userId,
            ...userInfo,
        });
    }
    return children;
};

export default SentryWrapper;

import React from 'react';
import PropTypes from 'prop-types';

const withMarketingSites = Component => {
    const WithMarketingSites = props => {
        if (!props.data) {
            return <Component {...props} />;
        }

        let sites;

        if (props.featureState) {
            sites = props.data.tsK6MarketingSiteUrlPages?.data.map(id => ({
                url: id.attributes.url,
                title: id.attributes.title,
            }));
        } else {
            sites = props.data.navigationData?.marketingsites?.relatedTo.all.edges.map(id => ({
                url: id.node.attributes.url,
                title: id.node.attributes.title,
            }));
        }
        return <Component {...props} marketingSites={sites} />;
    };

    WithMarketingSites.defaultProps = {
        data: null,
    };

    WithMarketingSites.propTypes = {
        data: PropTypes.shape({
            navigationData: PropTypes.shape({
                marketingsites: PropTypes.shape({
                    relatedTo: PropTypes.shape({
                        all: PropTypes.shape({
                            edges: PropTypes.any,
                        }),
                    }),
                }),
            }),
        }),
    };

    return WithMarketingSites;
};

export default withMarketingSites;

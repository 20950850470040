import styled from 'styled-components';
import { Button, Pill } from '@twigeducation/ts-fe-components/';
import { RightArrow } from '@twigeducation/ts-fe-components/components/Icons';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const TroubleShooterContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 0 1.813rem 110px;
    flex-grow: 1;
    align-items: start;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1364' height='404' viewBox='0 0 1364 404' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1370 1080.86C1151.23 1090.88 918.473 1035 671.713 913.232C454.134 805.864 220.563 786.444 -29 854.972L-4.8446 50.7654C111.774 1.9464 216.939 -11.6622 310.649 9.93967C586.612 73.554 688.902 373.037 1079.18 329.445C1183.3 317.815 1280.24 281.922 1370 221.764V1080.86Z' fill='%23E2F7FA'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: bottom 30px center;
    background-size: 100%;
`;

export const ContentArea = styled.div`
    flex: 3;
    padding-right: 1.25rem;

    p {
        line-height: 1.6;
    }
`;

export const Sidebar = styled.div`
    flex: 1;
    max-width: 350px;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-bottom: 1px;
    ${props => props.theme.twigPinkColor}
`;

export const StyledPill = styled(Pill)`
    background-color: ${props => props.theme.twigPinkColor};
    stroke-width: 6px;
    stroke: ${props => props.theme.twigPinkColor};
    color: ${props => props.theme.twigPinkColor};
`;

export const StyledAside = styled.aside`
    background: ${props => props.theme.twigBlueColor};
    color: #fff;
    align-self: stretch;
    padding: 20px;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    h5 {
        color: #fff;
    }
`;

export const StyledButton = styled(Button)`
    border-radius: 100px;
    border: 2px solid #128383;
    background: #fff;
    color: #128383;
    padding: 10px;
    font-weight: 700;
    letter-spacing: -0.002px;
    &:hover {
        text-decoration: none;
    }
`;

export const Footer = styled.footer`
    background: ${props => props.theme.twigBlueColor};
    color: #fff;
    height: 100px;
    text-align: center;
    padding: 15px 15px 15px 15px;
    vertical-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    align-items: center;
    display: flex;
`;

export const FooterText = styled.p`
    margin: 0;
    align-items: center;
    a {
        color: #fff;
        text-decoration: none;
        margin-right: 10px;
        margin-left: 10px;

        &:hover {
            text-decoration: none;
        }
    }
    text {
        margin-left: 10px;
        margin-right: 10px;
    }
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center; /* Center the SVG vertically */
    justify-content: center; /* Center the SVG horizontally */
    height: 100%; /* Double the text size */
    width: auto;

    ImagineLearning {
        height: 100%; /* Scale SVG to fit the container */
        width: auto;
    }
    scale: 0.5;
`;

export const Header = styled.header`
    background-color: ${props => props.theme.twigBlueColor};
    color: #fff;
    text-align: center;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 29px;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const StyledHeader = styled.header`
    background: #f8f8f8;
    height: 68px;
    padding: 18px 29px;
    width: 100%;
    top: 0;
    left: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

export const StyledUL = styled.ul`
    color: #343a72;
    list-style: none;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.002px;
    li + li {
        margin-top: 0.5vh;
    }

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            text-decoration: none;
            color: #343a72;
        }
        a:hover {
            text-decoration: underline;
        }
    }
`;
export const HeaderContainer = styled.div`
    padding-left: 1.813rem;
    padding-top: 1.813rem;
`;

export const StyledArrow = styled(RightArrow)`
    vertical-align: middle;
    display: inline-block;
    margin-left: auto;
`;

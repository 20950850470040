import React from 'react';
import { ImagineLearning, TwigScience } from '@twigeducation/ts-fe-components/components/Logos';
import { FeatureFlag } from '@twigeducation/unleash-client-react';
import {
    ContentArea,
    Footer,
    FooterText,
    HeaderContainer,
    LogoContainer,
    PageContainer,
    Sidebar,
    StyledArrow,
    StyledAside,
    StyledButton,
    StyledHeader,
    StyledPill,
    StyledUL,
    TroubleShooterContainer,
} from './Troubleshooter.styled';
import DashboardPanel from '../../components/DashboardPanel';

const TroubleShooter = () => {
    const HeaderImageSize = 163.923;
    return (
        <FeatureFlag
            flagKey="tsc-troubleshooter"
            renderFeatureCallback={() => (
                <PageContainer>
                    <StyledHeader>
                        <TwigScience width={HeaderImageSize} />
                    </StyledHeader>
                    <HeaderContainer>
                        <h3>Troubleshooter</h3>
                        <StyledPill />
                    </HeaderContainer>
                    <TroubleShooterContainer>
                        <ContentArea>
                            <p>
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting Troubleshooting Troubleshooting
                                Troubleshooting Troubleshooting Troubleshooting
                            </p>
                        </ContentArea>
                        <Sidebar>
                            <DashboardPanel title="Helpful Links">
                                <StyledUL>
                                    <li>
                                        <a
                                            href="https://help.twigscience.com/hc/en-us/articles/14682502726423-System-Requirements"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            System Requirements <StyledArrow size={10} />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://help.twigscience.com/hc/en-us/articles/14682795948183-Supported-Integrations-for-Elementary"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Supported Integrations for Elementary <StyledArrow size={10} />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://help.twigscience.com/hc/en-us/articles/14682408079511-Supported-Integrations-for-Middle-School"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Supported Integrations for Middle School <StyledArrow size={10} />
                                        </a>
                                    </li>
                                </StyledUL>
                            </DashboardPanel>
                            <StyledAside>
                                <h5>Still needing help?</h5>
                                <p>
                                    Join other users, like you, who want to help us test out great new features and help
                                    make our our products even better for teachers and students.
                                </p>
                                <StyledButton
                                    href="https://help.twigscience.com/hc/en-us/requests/new"
                                    link
                                    target="_blank"
                                >
                                    Contact Support
                                </StyledButton>
                            </StyledAside>
                        </Sidebar>
                    </TroubleShooterContainer>
                    <Footer>
                        <LogoContainer>
                            <ImagineLearning primaryColor="#ffffff" />
                        </LogoContainer>
                        <FooterText>
                            <a href="https://twigeducation.com/privacy" target="_blank" rel="noreferrer">
                                Privacy Policy
                            </a>
                            |
                            <a href="https://app.twigscience.com/terms-of-use" target="_blank" rel="noreferrer">
                                Terms and Conditions
                            </a>
                            |<text> Copyright © 2024, All rights reserved </text>
                        </FooterText>
                    </Footer>
                </PageContainer>
            )}
        />
    );
};

export default TroubleShooter;

import React from 'react';
import { useSelector } from 'react-redux';
import termsOfUseUrl from './urls';
import AuthenticatedLayoutRoute from '../../layout/AuthenticatedLayoutRoute';
import RoleErrorComponent from '../../components/Error/RoleErrorComponent';

const allowedRoles = ['TEACHER', 'STUDENT', 'SCHOOL_ADMIN', 'DISTRICT_OWNER'];

const TermsOfUse = React.lazy(() => import(/* webpackChunkName: "TermsOfUse" */ './TermsOfUse'));
const routes = () => {
    const routesMap = [];
    const wixTermsOfUseFF = useSelector(state => state.featureFlags['wix-terms-of-use']);
    if (wixTermsOfUseFF) {
        routesMap.push(
            <AuthenticatedLayoutRoute
                component={TermsOfUse}
                exact
                key={TermsOfUse}
                path={termsOfUseUrl}
                RoleErrorComponent={RoleErrorComponent}
                roles={allowedRoles}
                withSwoosh
            />,
        );
    } else {
        routesMap.push(
            <AuthenticatedLayoutRoute
                path={termsOfUseUrl}
                component={TermsOfUse}
                RoleErrorComponent={RoleErrorComponent}
                roles={allowedRoles}
            />,
        );
    }
    return routesMap;
};
export default routes;
